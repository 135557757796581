import React, { useState } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from 'react-redux'
import { addToCart, removeFromCart, removeFromCartInput, addToCartInput } from "../../redux/slises/cartSlice";

import iconPic from "../../img/icc.svg";
import { Link } from "react-router-dom";

const Product = ({ item, totalQuantity }) => {
	const dispatch = useDispatch()
	const [quantity, setQuantity] = useState("")
	const [info, setInfo] = useState(false)
	const cartSlice = useSelector((state) => state.cartSlice)
	//console.log(cartSlice)
	// актуальный элемент с ценой
	const actualEl = item.prices.find(it => totalQuantity >= it.quantity && totalQuantity <= it.quantityMax)
	const actualPrice = cartSlice.selectedPrice
	console.log(actualPrice)
	const increment = (actualPrice) => {
		console.log(actualPrice);
		setQuantity(Number(quantity) + 1);
		dispatch(addToCart({ item, actualPrice }));
	};

	const clickInfo = () => {
		setInfo(!info)
	}

	
	
	// Вызов функции increment с передачей actualPrice
	
	const decrement = () => {
		if (quantity) {
			setQuantity(quantity - 1)
			dispatch(removeFromCart(item.id))
		} else {
			setQuantity(null)
		}
		
	}
	const pricesAll = item.prices

	

	const inputHandler = (e) => {
		if (e.target.value * 1) {
			setQuantity(Number(e.target.value))
			dispatch(addToCartInput({...item, quantity: Number(e.target.value)}))
			console.log({...item, quantity: e.target.value})
		} else {
			if (e.target.value === "") {
				setQuantity("")
				dispatch(removeFromCartInput(item.id))
			} else {
				return
			}
			
		}
		
	}
	// console.log(actualPrice)
	// console.log(item.prices)
	// console.log(actualEl)

    // console.log(item.price);
    // console.log(item.properties);
    return (
		<div className='product'>
			<div className="product_ic"><img src={ iconPic } alt="ic" /></div>
			<div className="product_ic product_ic-2"><img src={ iconPic } alt="ic" /></div>
			<div className='product_wrap'>
				<Link to="/product">
                	<div className='product_pic'>
                   	 <img src={`/img/${item.img}`} alt={item.productName} />
					</div>
				</Link>
                <div className='product_info'>
                    <div className='product_row'>
                        <div className='product_nm'>{item.productName}</div>
						<div className='product_price'><span className={ classNames({
							act: item.prices[0].price !== actualPrice
						})}>80</span>{item.prices[0].price !== actualPrice && actualPrice} ₴</div>
                    </div>
                    <div className='product_row'>
                        {item.properties.map((prop, i) => {
                            return (
                                <div
                                    className='product_composition flex'
                                    key={i}
								>
									
										<div className='product_composition-pic'>
											<img
												src={`/img/${prop.pic}`}
												alt={item.text}
											/>
										</div>
									
                                    
                                    <div className='product_composition-text'>
                                        {prop.text}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className='product_row'>
                        <div className='product_descr'>
                            <p>{item.descripion}</p>
                        </div>
                    </div>
                    <div className='product_row product_buttons'>
                        <div className='product_buttons-ic product_buttons-min' onClick={decrement}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='2'
                                viewBox='0 0 16 2'
                                fill='none'
                            >
                                <path
                                    d='M1 1H15'
                                    stroke='#CBCBCB'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                            </svg>
                        </div>
						<div className={classNames('product_buttons-count', {
							disabled: !quantity,
						}) }> <input
								type="text"
								onChange={ (e) => inputHandler(e) }
								value={ quantity }
								placeholder="0"
							/> </div>
                        <div className='product_buttons-ic product_buttons-plus'  onClick={() => increment(actualPrice)}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='16'
                                viewBox='0 0 16 16'
                                fill='none'
                            >
                                <path
                                    fillRule='evenodd'
                                    clipRule='evenodd'
                                    d='M8 0C8.55228 0 9 0.447715 9 1V7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H9V15C9 15.5523 8.55228 16 8 16C7.44772 16 7 15.5523 7 15V9H1C0.447715 9 0 8.55228 0 8C0 7.44772 0.447715 7 1 7H7V1C7 0.447715 7.44772 0 8 0Z'
                                    fill='#42623B'
                                />
                            </svg>
                        </div>
                    </div>
                    <div className='product_text'>
						<p className="product_text-prev" onClick={clickInfo}>При великих замовленнях знижка <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M8.37378 9.3503L12.7644 4.95964C13.0248 4.69933 13.4468 4.69933 13.7071 4.95964C13.9674 5.21995 13.9674 5.64199 13.7071 5.9023L8.37378 11.2356L3.04045 5.9023C2.78014 5.64199 2.78014 5.21995 3.04045 4.95964C3.30076 4.69933 3.72281 4.69933 3.98312 4.95964L8.37378 9.3503Z" fill="#98979A"/>
</svg></p>
						{info && <div className="product_text-sales">
							<p>1-5 шт = 80 грн/цукерка</p>
						</div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Product;
