import React from 'react';
import s from "./footer.module.sass";
import Button from '../../ui/Button';

import logo from "../../img/logo.svg";

const Footer = () => {
	return (
		<div className={s.footer}>
			<div className="container">
				<div className={s.row}>
					<div className={ s.footer_item }>
						<ul>
							<li><a href="#">Цукерки</a></li>
							<li><a href="#">Придбати</a></li>
							<li><a href="#">Про цукерку Rabbit Black</a></li>
							<li><a href="#">Про цукерку Rabbit Banana</a></li>
						</ul>
					</div>
					<div className={ s.footer_item }>
						<ul>
							<li><a href="#">Кабінет</a></li>
							<li><a href="#">Мої замовлення</a></li>
							<li><a href="#">Моя знижка</a></li>
						</ul>
					</div>
					<div className={ s.footer_item }>
						<ul>
							<li><a href="#">Інфо</a></li>
							<li><a href="#">Контакти</a></li>
							<li><a href="#">Співпраця</a></li>
							<li><a href="#">Про нас</a></li>
							<li><a href="#">Блог</a></li>
							<li><a href="#">Місця продажу</a></li>
						</ul>
					</div>
					<div className={ s.footer_item }>
						<p>Давай знайомитись. </p>
						<p>Друзям зайчик робить знижки!</p>
						<Button text="Реєстрація / Вхід" className="button" />
					</div>
				</div>
			</div>
			<div className="container">
				<div className={s.bottom}>
					<div className={s.row}>
						<div className="logo col">
							<img src={ logo } alt="logo" />
						</div>
						<div className="right col">
							<p>© CBD Rabbit. All Rights Reserved. </p>
							<p>Privacy Policy  | Terms & Conditions</p>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	);
};

export default Footer;
