import React from 'react';

import info from "../../../img/info.svg";
import arr2 from "../../../img/arrow-doble.svg";

const CheckoutRes = () => {
	return (
		<div className='checkoutRes'>
                    <div className='container'>
                        <div className='checkoutRes_error'>
                            <div className='checkoutRes_error-pic'>
                                <img src={arr2} alt='arr2' />
                            </div>
                            <p>
                                Щоб оформити відправлення заповніть усі поля *
                            </p>
                        </div>
                        <div className='checkoutRes_prices'>
                            <div className='checkoutRes_row'>
                                <div className='checkoutRes_nm'>
                                    Вартість замовлення
                                </div>
                                <div className='checkoutRes_price'>240 ₴</div>
                            </div>
                            <div className='checkoutRes_row'>
                                <div className='checkoutRes_nm'>
                                    Персональна знижка 0%
                                </div>
                                <div className='checkoutRes_price'>- 0 ₴</div>
                            </div>
                            <div className='checkoutRes_row'>
                                <div className='checkoutRes_nm'>
                                    Знижка за кількість 0%
                                </div>
                                <div className='checkoutRes_price'>- 0 ₴</div>
                            </div>
                            <div className='info'>
                                <div className='info_ic'>
                                    <img src={info} alt='info' />
                                </div>
                                <div className='info_content'>
                                    <div className='info_ttl'>
                                        <p>
                                            Ми не враховуємо вартість доставки
                                        </p>
                                        <div className='info_row'>
                                            <div className='info_nm'>
                                                <p>Нова Пошта</p>
                                            </div>
                                            <div className='info_price'>
                                                <p>Від 50 ₴</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='checkout-total'>
                                <div className='checkout-total_ttl'>
                                    <p>До оплати без доставки:</p>
                                </div>
                                <div className='checkout-total_res'>
                                    <p>240 ₴</p>
                                </div>
                                <button className='button'>
                                    Перейти до оплати
                                </button>
                                <div className='checkout-total_check'>
                                    <p>
                                        Натискаючи “Перейти до оплати” я
                                        підтверджую що введені мною дані вірні
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
	);
};

export default CheckoutRes;
