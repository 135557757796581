import React from "react";
import cn from "clsx";
import s from "./s.module.sass";

import ii1 from "../../../img/ii1.svg";
import ii2 from "../../../img/ii2.svg";
import ii3 from "../../../img/ii3.svg";
import ii4 from "../../../img/ii4.svg";
import ii5 from "../../../img/ii5.svg";

const Advantages = () => {
    return (
        <section className={`s-section ${s.adventages}`}>
            <div className='container'>
                <div className={s.adventages_row}>
                    <div className={cn(s.adventages_item, s.adventages_itemBig)} >
                        <div className='adventages_itemWrap'>
                            <div className={s.adventages_itemImg}>
                                <img src={ii1} alt='ii1' />
                            </div>
                            <div className='adventages_item-ttl'>ВЕГАН</div>
                            <div className={s.adventages_itemDescr}>
                                У продукції немає сухого молока
                            </div>
                        </div>
                    </div>
                    <div className={cn(s.adventages_item, s.adventages_itemBig)} >
                        <div className={s.adventages_itemWrap}>
                            <div className={s.adventages_itemImg}>
                                <img src={ii2} alt='ii2' />
                            </div>
                            <div className={s.adventages_itemTtl}>БЕЗ ЦУКРУ</div>
                            <div className={s.adventages_itemDescr}>
                                Замість цукру ми використовуємо фруктозу
                            </div>
                        </div>
                    </div>
                </div>

                <div className={s.adventages_row}>
                    <div className={cn(s.adventages_item, s.adventages_itemSmall)}>
                        <div className={s.adventages_itemWrap}>
                            <div className={s.adventages_itemImg}>
                                <img src={ii3} alt='ii3' />
                            </div>
                            <div className={s.adventages_itemDescr}>
                                Цукерка щоб розслабитися
                            </div>
                        </div>
                    </div>
                    <div className={cn(s.adventages_item, s.adventages_itemSmall)}>
                        <div className={s.adventages_itemWrap}>
                            <div className={s.adventages_itemImg}>
                                <img src={ii4} alt='ii4' />
                            </div>
                            <div className={s.adventages_itemDescr}>
                                Розсмоктуй під язиком
                            </div>
                        </div>
                    </div>
                    <div className={cn(s.adventages_item, s.adventages_itemSmall)}>
                        <div className={s.adventages_itemWrap}>
                            <div className={s.adventages_itemImg}>
                                <img src={ii5} alt='ii5' />
                            </div>
                            <div className={s.adventages_itemDescr}>
                                Можна дітям
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.buttonBlock}>
					<button className={ cn("button button--yellow", s.button)}>Придбати</button>
                </div>
            </div>
        </section>
    );
};

export default Advantages;
