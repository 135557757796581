import React from "react";
import logo from "../../img/logo.svg";
import mnu from "../../img/mnu.svg";
import basket from "../../img/basket.svg";
import arrowL from "../../img/arrowL.svg";
import { Link } from "react-router-dom";

import insta from "../../img/insta.svg";
import tg from "../../img/tg.svg";
import logo2 from "../../img/rabbit.svg";

const Header = (props) => {
    console.log(props)
    const [menu, setMenu] = React.useState(false);

    const handlerMnu = () => {
        setMenu(!menu);
    };
    return (
        <div className='header'>
            <div className='container'>
                <div className='row'>
                    {!props.prev ? (
                        <>
                            <Link to='/'>
                                <div className='logo col'>
                                    <img src={logo} alt='logo' />
                                </div>
                            </Link>
                            <div className='header_part col'>
                                <div className='header_icon header_basket'>
                                    <img src={basket} alt='logo' />
                                </div>
                                <div
                                    className='header_icon header_mnu'
                                    onClick={handlerMnu}
                                >
                                    <img src={mnu} alt='logo' />
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
								<div className='header_icon '>
									{props.logo ? <img src={logo2} alt='logo' /> : <img src={arrowL} alt='prev' />}
                                
                            </div>
								<div className='h1'>{ props.h1 }</div>
								<div className="col mnu-col"><div
                                    className='header_icon header_mnu'
                                    onClick={handlerMnu}
                                >
                                    <img src={mnu} alt='logo' />
                                </div></div>
                        </>
                    )}
                </div>
            </div>
            {menu && (
                <div className='mnu'>
                    <div className='wnu_wrap'>
                        <div className='header_icon close' onClick={handlerMnu}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='20'
                                height='20'
                                viewBox='0 0 20 20'
                                fill='none'
                            >
                                <path
                                    d='M16 4L4 16'
                                    stroke='#69332B'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <path
                                    d='M4 4L16 16'
                                    stroke='#69332B'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                            </svg>
                        </div>
                        <div className='wnu_content'>
                            <ul className='mnu_list'>
                                <li className='mnu_link1'>
                                    Увійти / Реєстрація
                                </li>
                                <li className='mnu_link'>
                                    <Link to='/'>Home</Link>
                                </li>
                                <li className='mnu_link'>
                                    <Link to='/checkout'>Оформлення</Link>
                                </li>
                                <li className='mnu_link'>
									<Link to='/success'>Успіх</Link>
                                </li>
                                <li className='mnu_link'>
									<Link to='/orders'>Мої замовлення</Link>
                                </li>
                                <li className='mnu_link'>
									<Link to='/profile'>Мій профіль</Link>
                                </li>
                            </ul>
                            <div className='ssoc'>
                                <p>Соц мережі</p>
                                <a href='#'>
                                    <img src={insta} alt='insta' />
                                </a>
                                <a href='#'>
                                    <img src={tg} alt='tg' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Header;
