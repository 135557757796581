import React from "react";

import Input from "../../../ui/Input";

const LayEmail = (props) => {
    //console.log(props.bottomBlock);
    const [status, setStatus] = React.useState("2");
    return (
        <div className='lay_wrap email'>
            <div className='container'>
                {status === "1" ? (
                    <div className='flexLay'>
                        <div className='lay_ttl'>
                            <p>
                                Змінити Email
                                <span
                                    className='close'
                                    onClick={() => props.bottomBlock()}
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        viewBox='0 0 20 20'
                                        fill='none'
                                    >
                                        <path
                                            d='M16 4L4 16M4 4L16 16'
                                            stroke='#98979A'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                    </svg>
                                </span>
                            </p>
                        </div>

                        <div className='lay_text'>
                            <p>
                                Вкажи який email ти хочеш замість
                                ojo*****@gmail.com
                            </p>
                        </div>
                        <Input
                            type='text'
                            text='Новий Email'
                            placeholder='Введи новий Email'
                        />
                        <div className='button-block'>
                            <p>
                                Ми вишлемо лист з лінком для підтвердження на
                                пошту
                            </p>
                            <button className='button button--white'>
                                Змінити
                            </button>
                        </div>
                    </div>
                ) : status === "2" ? (
                    <div className='flexLay'>
                        <div className='lay_ttl'>
                            <p>
                                Змінити Email
                                <span
                                    className='close'
                                    onClick={() => props.bottomBlock()}
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        viewBox='0 0 20 20'
                                        fill='none'
                                    >
                                        <path
                                            d='M16 4L4 16M4 4L16 16'
                                            stroke='#98979A'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                    </svg>
                                </span>
                            </p>
                        </div>

                        <div className='lay_text'>
                            <p>
                                Вкажи який email ти хочеш замість
                                ojo*****@gmail.com
                            </p>
                        </div>
                        <Input
                            type='text'
                            text='Новий Email'
                            placeholder='Введи новий Email'
                        />
                        <div className='button-block'>
                            <p>
                                Ми вишлемо лист з лінком для підтвердження на
                                пошту
                            </p>
                            <button className='button button--white'>
                                Змінити
                            </button>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default LayEmail;
