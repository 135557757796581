import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  items: []
}

export const fetchSlice = createSlice({
	name: 'items',
	initialState,
	reducers: {
		setProducts: (state, action) => {
            state.items = action.payload;
        }
	},
  })

  // Action creators are generated for each case reducer function
  export const { setProducts } = fetchSlice.actions
  
  export default fetchSlice.reducer
