import React from 'react';
import Header from '../components/Header/Header';

import success from "../img/success-img.svg";

const Success = () => {
	return (
		<div className='wrapPage'>
			<Header logo prev h1="Успіх!" />
			<div className="wrapper">
				<div className="wrapper_wrap">
					<div className="wrapper_ttl">
						<p>Успішно оплачено!</p>
					</div>
					<div className="wrapper_descr">
						<p>Цукерочки скоро поскачуть до вас</p>
					</div>
					<div className="wrapper_img">
						<img src={success} alt="success" />
					</div>
					<div className="wrapper_info">
						<p>Email успішно оновлено!</p>
					</div>
					<button className='button'>Чудово!</button>
				</div>
				
			</div>
		</div>
	);
};

export default Success;
