import React from "react";
import { useSelector } from 'react-redux'
import { addToCart } from "../redux/slises/cartSlice";

const BottomCard = () => {
	const totalPrice = useSelector((state) => state.cartSlice.totalPrice)
    return (
        <div className='cartBottomPart'>
            <div className='container'>
				<div className='row'>
					<div className="cartBottomPart_elem">
						<p className="cartBottomPart_elem-text">До оплати</p>
						<p className="cartBottomPart_elem-price">{totalPrice} ₴</p>
					</div>
                    <div className='button-block col'>
                        <button type='button' className='button'>
                            Оформити{" "}
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='9'
                                height='15'
                                viewBox='0 0 9 15'
                                fill='none'
                            >
                                <path
                                    fillRule='evenodd'
                                    clipRule='evenodd'
                                    d='M6.15498 7.7453L0.666648 2.25697C0.34126 1.93158 0.341261 1.40402 0.666648 1.07864C0.992036 0.753248 1.51959 0.753248 1.84498 1.07864L8.51165 7.7453L1.84498 14.412C1.51959 14.7374 0.992036 14.7374 0.666648 14.412C0.34126 14.0866 0.341261 13.559 0.666648 13.2336L6.15498 7.7453Z'
                                    fill='white'
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BottomCard;
