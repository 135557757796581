import React from "react";
import classNames from "classnames";

const InputNp = ({ text, novaposhtaCities, novaposhtaCity, newPostNum, numbersNovaPost,  cities, required, ...input }) => {
    const { name } = input;
    const [value, setValue] = React.useState("");
    const [validate, setValidate] = React.useState(true);
    const [isShowDropdown, setIsShowDropdown] = React.useState(true);

    const clickInput = () => {
        setValue("")
    };
	const handlerInput = (e) => {
		setIsShowDropdown(true)
        setValue(e.target.value);
		if (name === "Населений пункт") {
            novaposhtaCities(e.target.value);
		} else if (name === "Відділення") {
			newPostNum(e.target.value)
		}
        
    };
    const validateInput = () => {
        if (!value.trim()) {
            setValidate(false);
        }
	};
	const selectedItem = (city) => {
		setValue(city.Present)
		if (name === "Населений пункт") {
            novaposhtaCity(city);
		}
		setIsShowDropdown(false)
	}

    console.log(numbersNovaPost);
    return (
		<div className='dropdown'>
			{cities && isShowDropdown ? <ul className='dropdown_list'>
				{ name === "Населений пункт" && cities ? cities.map(it => {
					return <li key={it.DeliveryCity} onClick={() => selectedItem(it)}>{it.Present }</li>
				}) : null}
               
            </ul> : null}
			{numbersNovaPost && isShowDropdown ? <ul className='dropdown_list'>
				{ name === "Відділення" && numbersNovaPost ? numbersNovaPost.map(it => {
					return <li key={it.Description
					} onClick={() => selectedItem(it)}>{it.Description
					}</li>
				}) : null}
               
            </ul> : null}
			
            <label
                className={classNames("label", {
                    error: !validate,
                })}
            >
                <p className='label_ttl'>
                    {text} {required && <span className='red'>*</span>}
                </p>
                <input
					{ ...input }
					onClick={clickInput}
                    onChange={(e) => handlerInput(e)}
                    onBlur={validateInput}
                    value={value}
                />
            </label>
        </div>
    );
};

export default InputNp;
