import { configureStore } from '@reduxjs/toolkit'
import counterSlice from "./slises/counterSlice";
import fetchSlice from "./slises/fetchSlice";
import cartSlice from "./slises/cartSlice";

export const store = configureStore({
	reducer: {
		counterSlice,
		fetchSlice,
		cartSlice
  },
})
