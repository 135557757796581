import axios from "axios";
import React from "react";
import { useSelector, useDispatch } from 'react-redux'
import Product from "../components/Product/Product";
import {setProducts} from "../redux/slises/fetchSlice";
import BottomCard from "../components/BottomCard";
import Header from "../components/Header/Header";

const Home = () => {
	const items = useSelector((state) => state.fetchSlice.items)
	const cartSlice = useSelector((state) => state.cartSlice) // корзина
	const totalQuantity = cartSlice.totalQuantity // количество в корзине
	//const ste = useSelector((state) => state)
	const dispatch = useDispatch()
	//console.log(totalQuantity)
    React.useEffect(() => {
        const getItems = async () => {
            try {
                const resp = await axios.get(
                    `https://65fc764b9fc4425c65302bdf.mockapi.io/sa/items`
				);
				console.log(resp.data[0])
				console.log(resp.data[0].items)
				dispatch(setProducts(resp.data[0].items))
            } catch (error) {
                console.log("error", error);
            }
        };
        getItems();
    }, []);
    return (
		<section className='s-home s-section'>
			<Header />
			<div className="s-home-bg1"></div>
			<div className="s-home-bg2"></div>
            <div className='container'>
                <div className='row'>
					<div className='section-wrap'>
						{ items.length && items.map(item => {
							return <Product key={item.id} item={item} totalQuantity={totalQuantity} />
						})}
                        
                    </div>
                </div>
			</div>
			<BottomCard />
        </section>
    );
};

export default Home;
