import './App.sass';

import { Routes, Route } from "react-router-dom";
// import { useSelector, useDispatch } from 'react-redux'
// import { decrement, increment } from './redux/slises/counterSlice'
import Home from './pages/Home';
import CheckOut from './pages/CheckOut';
import Success from './pages/Success';
import MyOrders from './pages/MyOrders';
import MyProfile from './pages/MyProfile';
import ProductPage from './pages/ProductPage/ProductPage';

function App() {
	// const count = useSelector((state) => state.counterSlice.value)
	// const dispatch = useDispatch()
  return (
	  <div className="App">
		   <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/checkout' element={<CheckOut />} />
                    <Route path='/success' element={<Success />} />
                    <Route path='/orders' element={<MyOrders />} />
                    <Route path='/profile' element={<MyProfile />} />
                    <Route path='/product' element={<ProductPage />} />
                </Routes>
      {/* <button
          aria-label="Increment value"
          onClick={() => dispatch(increment())}
        >
          Increment
        </button>
        <span>{count}</span>
        <button
          aria-label="Decrement value"
          onClick={() => dispatch(decrement())}
        >
          Decrement
        </button> */}
    </div>
  );
}

export default App;
