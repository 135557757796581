import React from "react";
import { CSSTransition, Transition } from "react-transition-group";
import classNames from "classnames";
import Header from "../components/Header/Header";

import pencil from "../img/pencil.svg";
import arro from "../img/arro-bot.svg";
import np from "../img/np.svg";
import sale from "../img/sale.svg";
import check from "../img/checkr.svg";

import Input from "../ui/Input";
import InputNp from "../ui/InputNp";
import Button from "../ui/Button";

import LayDelete from "../components/Lays/LayDelete/LayDelete";
import LayEmail from "../components/Lays/LayEmail/LayEmail";
import LayPassword from "../components/Lays/LayPassword/LayPassword";
import NpBlock from "../components/NpBlock/NpBlock";

const MyProfile = () => {
    const [isDetailes, setIsDetailes] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [actualLay, setActualLay] = React.useState(false);

    const toggleBlock = () => {
        setIsDetailes(!isDetailes);
    };
    const bottomBlock = (info) => {
        setActualLay(info);
        setIsOpen(!isOpen);
    };
    return (
        <div className='wrapPage profile'>
            <Header logo prev h1='Мій профіль' />
            <div className='wrapper'>
                <div className='wrapper_wrap'>
                    <div className='wrapper_ttl'>
                        <p>Моя персональна знижка</p>
                    </div>
                    <div className='wrapper_big'>
                        <p>5%</p>
                    </div>
                    <div className='profile_details' onClick={toggleBlock}>
                        <p className='profile_details-one'>
                            Деталі <img src={arro} alt='arro' />
                        </p>
                        <CSSTransition
                            in={isDetailes}
                            timeout={500}
                            classNames='details'
                            unmountOnExit // Не удалять элемент из DOM после анимации
                        >
                            <div className='details'>
                                <div className='details_grey'>
                                    <p>Ви вже придбали на </p>
                                    <p className='details_big'>1375 ₴</p>
                                </div>
                                <div className='details_white'>
                                    <p>
                                        Чим більше цукерок ви купуєте - тим
                                        більшу знижку отримуєте! Це наш спосіб
                                        сказати "Дякуємо!"
                                    </p>
                                </div>
                                <div className='details_img'>
                                    <img src={sale} alt='sale' />
                                </div>
                                <div className='details_sales'>
                                    <div className='details_row'>
                                        <div className='details_ttl'>
                                            <p>
                                                <img src={check} alt='check' />
                                                Реєстрація
                                            </p>
                                        </div>
                                        <div className='details_descr'>
                                            <p>2% знижка</p>
                                        </div>
                                    </div>
                                    <div className='details_row'>
                                        <p className='details_bold'>
                                            Сума усіх покупок:
                                        </p>
                                    </div>
                                    <div className='details_row'>
                                        <div className='details_ttl'>
                                            <p>
                                                <img src={check} alt='check' />
                                                Від 1000₴
                                            </p>
                                        </div>
                                        <div className='details_descr'>
                                            <p>5% знижка</p>
                                        </div>
                                    </div>
                                    <div className='details_row'>
                                        <div className='details_ttl'>
                                            <p>Від 3000₴</p>
                                        </div>
                                        <div className='details_descr'>
                                            <p>7% знижка</p>
                                        </div>
                                    </div>
                                    <div className='details_row'>
                                        <div className='details_ttl'>
                                            <p>Від 5000₴</p>
                                        </div>
                                        <div className='details_descr'>
                                            <p>9% знижка</p>
                                        </div>
                                    </div>
                                    <div className='details_row'>
                                        <div className='details_ttl'>
                                            <p>Від 7000₴</p>
                                        </div>
                                        <div className='details_descr'>
                                            <p>12% знижка</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CSSTransition>
                    </div>
                </div>
                <div className='wrapper_wrap ta checkoutBlock'>
                    <div className='checkoutBlock_h2'>
                        Email{" "}
                        <span
                            className='pencil'
                            onClick={() => bottomBlock("email")}
                        >
                            <img src={pencil} alt='pencil' />
                        </span>
                    </div>
                    <p>ojo*****@gmail.com</p>
                </div>
                <div className='wrapper_wrap ta checkoutBlock'>
                    <div className='checkoutBlock_h2'>
                        Пароль
                        <span
                            className='pencil'
                            onClick={() => bottomBlock("password")}
                        >
                            <img src={pencil} alt='pencil' />
                        </span>
                    </div>
                    <p>Встановлено</p>
                </div>
                <div className='wrapper_wrap ta checkoutBlock'>
                    <div className='checkoutBlock_h2'>
                        Дані для автозаповнення доставки{" "}
                        <span
                            className='pencil'
                            onClick={() => bottomBlock("delivery")}
                        >
                            <img src={pencil} alt='pencil' />
                        </span>
                    </div>
                    <div className='checkoutBlock_grey'>
                        <p>
                            Ми не поширюємо ці дані і не використовуємо для
                            розсилок. Лише для швидшого оформлення замовлень
                        </p>
                    </div>

                    <div className='labels'>
                        <Input
                            type='text'
                            name='Телефон'
                            text='Телефон'
                            required='true'
                            placeholder='+380 (__)___-__-__'
                        />
                        <Input
                            type='text'
                            name='Прізвище'
                            text='Прізвище'
                            required='true'
                            placeholder='Введи прізвище кирилицею'
                        />
                        <Input
                            type='text'
                            name='Імʼя'
                            text='Імʼя'
                            required='true'
                            placeholder='Введи імʼя кирилицею'
                        />
                    </div>
                    <div className='checkoutBlock_np checkoutBlock_np--mt flex '>
                        <img src={np} alt='np' />
                        <div className='checkoutBlock_ttl'>Нова Пошта</div>
                    </div>
                    <Input
                        type='radio'
                        name='Спосіб доставки'
                        text='У відділення'
                        /* checkedValue={selectedRadio}
                            onRadioChange={handleRadioChange} */
                    />
                    <Input
                        type='radio'
                        name='Спосіб доставки'
                        text='У поштомат'
                        /* checkedValue={selectedRadio}
                            onRadioChange={handleRadioChange} */
                    />
                    <Input
                        type='radio'
                        name='Спосіб доставки'
                        text="Кур'єром"
                        /* checkedValue={selectedRadio}
                            onRadioChange={handleRadioChange} */
                    />
                    <InputNp
                        type='text'
                        name='Населений пункт'
                        text='Населений пункт'
                        required='true'
                        placeholder='Введи населений пункт'
                        /* novaposhtaCities={ novaposhtaCities }
							cities={ cities }
							novaposhtaCity={novaposhtaCity} */
                    />
                    <InputNp
                        type='text'
                        name='Відділення'
                        text='Відділення'
                        required='true'
                        placeholder='Введи номер відділення'
                        /* newPostNum={ newPostNum }
							numbersNovaPost={numbersNovaPost} */
                    />
                </div>
                <div className='wrapper_wrap ta checkoutBlock'>
                    <div className='checkoutBlock_h2'>Видалити акаунт</div>
                    <Button
                        onClick={() => bottomBlock("delete")}
                        text='Видалити'
                        className='button button--red'
                    />
                </div>
            </div>

            <div
                className={classNames("lay", {
                    active: isOpen,
                })}
            >
                <CSSTransition
                    in={isOpen && actualLay === "delete"}
                    timeout={500}
                    classNames='lay_wrap'
                    unmountOnExit // Удалять элемент из DOM после анимации
                >
                    <LayDelete bottomBlock={bottomBlock} />
                </CSSTransition>
                <CSSTransition
                    in={isOpen && actualLay === "email"}
                    timeout={500}
                    classNames='lay_wrap'
                    unmountOnExit // Удалять элемент из DOM после анимации
                >
                    <LayEmail bottomBlock={bottomBlock} />
                </CSSTransition>
                <CSSTransition
                    in={isOpen && actualLay === "password"}
                    timeout={500}
                    classNames='lay_wrap'
                    unmountOnExit // Удалять элемент из DOM после анимации
                >
                    <LayPassword bottomBlock={bottomBlock} />
                </CSSTransition>
                <CSSTransition
                    in={isOpen && actualLay === "delivery"}
                    timeout={500}
                    classNames='fullHeight'
                    unmountOnExit // Удалять элемент из DOM после анимации
                >
                    <NpBlock bottomBlock={bottomBlock} className="fullHeight" />
                </CSSTransition>
            </div>
        </div>
    );
};

export default MyProfile;
