import React from 'react';
import cn from "clsx";

import InputNp from '../../ui/InputNp';
import Input from '../../ui/Input';


import np from "../../img/np.svg";

const NpBlock = (props) => {
	return (
		<div className={cn("lay_wrap", props.className)}>
            <div className='container'>
		<div className='wrapper_wrap ta checkoutBlock'>
                    <div className='checkoutBlock_h2'>
				Дані для автозаповнення доставки
				<span
                                className='close'
                                onClick={() => props.bottomBlock()}
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    viewBox='0 0 20 20'
                                    fill='none'
                                >
                                    <path
                                        d='M16 4L4 16M4 4L16 16'
                                        stroke='#98979A'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                </svg>
                            </span>
                    </div>
                    <div className='checkoutBlock_grey'>
                        <p>
                            Ми не поширюємо ці дані і не використовуємо для
                            розсилок. Лише для швидшого оформлення замовлень
                        </p>
                    </div>

                    <div className='labels'>
                        <Input
                            type='text'
                            name='Телефон'
                            text='Телефон'
                            required='true'
                            placeholder='+380 (__)___-__-__'
                        />
                        <Input
                            type='text'
                            name='Прізвище'
                            text='Прізвище'
                            required='true'
                            placeholder='Введи прізвище кирилицею'
                        />
                        <Input
                            type='text'
                            name='Імʼя'
                            text='Імʼя'
                            required='true'
                            placeholder='Введи імʼя кирилицею'
                        />
                    </div>
                    <div className='checkoutBlock_np checkoutBlock_np--mt flex '>
                        <img src={np} alt='np' />
                        <div className='checkoutBlock_ttl'>Нова Пошта</div>
                    </div>
                    <Input
                        type='radio'
                        name='Спосіб доставки'
                        text='У відділення'
                        /* checkedValue={selectedRadio}
                            onRadioChange={handleRadioChange} */
                    />
                    <Input
                        type='radio'
                        name='Спосіб доставки'
                        text='У поштомат'
                        /* checkedValue={selectedRadio}
                            onRadioChange={handleRadioChange} */
                    />
                    <Input
                        type='radio'
                        name='Спосіб доставки'
                        text="Кур'єром"
                        /* checkedValue={selectedRadio}
                            onRadioChange={handleRadioChange} */
                    />
                    <InputNp
                        type='text'
                        name='Населений пункт'
                        text='Населений пункт'
                        required='true'
                        placeholder='Введи населений пункт'
                        /* novaposhtaCities={ novaposhtaCities }
							cities={ cities }
							novaposhtaCity={novaposhtaCity} */
                    />
                    <InputNp
                        type='text'
                        name='Відділення'
                        text='Відділення'
                        required='true'
                        placeholder='Введи номер відділення'
                        /* newPostNum={ newPostNum }
							numbersNovaPost={numbersNovaPost} */
					/>
					<button className="button button--red">Стерти дані доставки</button>
					<button className="button button--white">Відміна</button>
					<button className="button">Зберегти зміни</button>
                </div>
                </div>
                </div>
	);
};

export default NpBlock;
