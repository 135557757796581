import React from "react";

import delet from "../../../img/delete.svg";
import Button from "../../../ui/Button";

const LayDelete = (props) => {
	console.log(props.bottomBlock)
    return ( 
        <div className='lay_wrap'>
            <div className='container'>
                <div className='deleteAkk'>
                    <div className='lay_ttl'>
                        <p>
                            Видалити акаунт
                            <span
                                className='close'
                                onClick={() => props.bottomBlock()}
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    viewBox='0 0 20 20'
                                    fill='none'
                                >
                                    <path
                                        d='M16 4L4 16M4 4L16 16'
                                        stroke='#98979A'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                </svg>
                            </span>
                        </p>
                    </div>
                    <div className='lay_img'>
                        <img src={delet} alt='delet' />
                    </div>
                    <div className='lay_text'>
                        <p>Ви впевнені що хочете видалити акаунт?</p>
                    </div>
                    <div className='button-block'>
                        <button className='button button--white'>
                            Не хочу видаляти 🥺
                        </button>
						<Button text="Видалити 😭" className='button button--red' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LayDelete;
