import React from "react";
import axios from "axios";
import Header from "../components/Header/Header";
import Input from "../ui/Input";

import np from "../img/np.svg";
import pic1 from "../img/pic1.jpg";
import money from "../img/money.svg";

import InputNp from "../ui/InputNp";
import ProductChackout from "../components/ProductChackout/ProductChackout";
import CheckoutRes from "../components/PageCheckout/CheckoutRes/CheckoutRes";

const CheckOut = () => {
    const [selectedRadio, setSelectedRadio] = React.useState(""); // сюда текст от радиокнопки придет
    const [cities, setCities] = React.useState(""); //
    const [city, setCity] = React.useState(""); // город
    const [cityRef, setCityRef] = React.useState(""); //

    const [numbersNovaPost, setNumbersNovaPost] = React.useState(""); //

    console.log(cities);

    const handleRadioChange = (value) => {
        setSelectedRadio(value);
    };

    // const selectCity = (value) => {
    // 	setCity(value);
    // 	console.log(value)
    // };

    const novaposhtaCities = (e) => {
        let requestData = {
            apiKey: "b8468b363951e2f035a2f38c961bb23a",
            modelName: "Address",
            calledMethod: "searchSettlements",
            methodProperties: {
                CityName: e,
                Limit: 15,
            },
        };

        let settings = {
            method: "post",
            url: "https://api.novaposhta.ua/v2.0/json/",
            headers: {
                "Content-Type": "application/json",
            },
            data: requestData,
        };
        axios(settings)
            .then((response) => {
                console.log("Успешный ответ:", "");
                setCities(response.data.data[0].Addresses);
            })
            .catch((error) => {
                console.error("Ошибка при отправке запроса:", error);
            });
    };

    const novaposhtaCity = (obj) => {
        setCity(obj.Present);
        setCityRef(obj.DeliveryCity);
    };

    const newPostNum = (e) => {
        console.log(cityRef);
        let requestData = {
            apiKey: "b8468b363951e2f035a2f38c961bb23a",
            modelName: "Address",
            calledMethod: "getWarehouses",
            methodProperties: {
                CityRef: cityRef,
                Language: "ua",
            },
        };

        // Настройка параметров запроса
        let settings = {
            method: "post",
            url: "https://api.novaposhta.ua/v2.0/json/",
            headers: {
                "Content-Type": "application/json",
            },
            data: requestData,
        };
        axios(settings)
            .then((response) => {
                console.log("Успешный ответ:", response.data.data);
                let results;
                if (selectedRadio === "У відділення") {
                    results = response.data.data.filter((el) =>
                        el.CategoryOfWarehouse.includes("Branch")
                    );
                } else if (selectedRadio === "У поштомат") {
                    results = response.data.data.filter((el) =>
                        el.CategoryOfWarehouse.includes("Postomat")
                    );
                }
                //const branches = response.data.data.filter(el => el.CategoryOfWarehouse.includes("Branch"));
                //console.log(branches)
                const filteredData = results.filter((el) =>
                    el.Number.includes(e)
                );
                console.log(e);
                console.log(filteredData);
                setNumbersNovaPost(filteredData);
                // Обработка успешного ответа здесь
            })
            .catch((error) => {
                console.error("Ошибка при отправке запроса:", error);
                // Обработка ошибки здесь
            });
        console.log(e);
    };

    // React.useEffect(() => {

    // }, []);

    return (
        <div className='checoutPage'>
            <Header prev={true} h1='Оформлення' />
            <main>
                <div className='container'>
                    <div className='checkoutBlocks'>
                        <div className='checkoutBlock '>
                            <div className='checkoutBlock_h2'>
                                Ваше замовлення
                            </div>

                            <ProductChackout />
                            <ProductChackout />
                        </div>
                        <div className='checkoutBlock'>
                            <div className='checkoutBlock_h2'>
                                Контактні дані
                            </div>
                            <div className='labels'>
                                <Input
                                    type='text'
                                    name='Телефон'
                                    text='Телефон'
                                    required='true'
                                    placeholder='+380 (__)___-__-__'
                                />
                                <Input
                                    type='text'
                                    name='Прізвище'
                                    text='Прізвище'
                                    required='true'
                                    placeholder='Введи прізвище кирилицею'
                                />
                                <Input
                                    type='text'
                                    name='Імʼя'
                                    text='Імʼя'
                                    required='true'
                                    placeholder='Введи імʼя кирилицею'
                                />
                            </div>
                        </div>
                        <div className='checkoutBlock'>
                            <div className='checkoutBlock_h2 checkoutBlock_h2--mb'>
                                Доставка
                            </div>
                            <div className='checkoutBlock_np flex'>
                                <img src={np} alt='np' />
                                <div className='checkoutBlock_ttl'>
                                    Нова Пошта
                                </div>
                                <div className='checkoutBlock_price'>
                                    Від 50 ₴
                                </div>
                            </div>
                            <Input
                                type='radio'
                                name='Спосіб доставки'
                                text='У відділення'
                                checkedValue={selectedRadio}
                                onRadioChange={handleRadioChange}
                            />
                            <Input
                                type='radio'
                                name='Спосіб доставки'
                                text='У поштомат'
                                checkedValue={selectedRadio}
                                onRadioChange={handleRadioChange}
                            />
                            <Input
                                type='radio'
                                name='Спосіб доставки'
                                text="Кур'єром"
                                checkedValue={selectedRadio}
                                onRadioChange={handleRadioChange}
                            />
                            <InputNp
                                type='text'
                                name='Населений пункт'
                                text='Населений пункт'
                                required='true'
                                placeholder='Введи населений пункт'
                                novaposhtaCities={novaposhtaCities}
                                cities={cities}
                                novaposhtaCity={novaposhtaCity}
                            />
                            <InputNp
                                type='text'
                                name='Відділення'
                                text='Відділення'
                                required='true'
                                placeholder='Введи номер відділення'
                                newPostNum={newPostNum}
                                numbersNovaPost={numbersNovaPost}
                            />
                        </div>
                        <div className='checkoutBlock'>
                            <div className='checkoutBlock_h2'>
                                Оплата карткою на сайті
                            </div>
                            <div className='checkoutBlock_img'>
                                <img src={money} alt='money' />
                            </div>
                            <div className='checkoutBlock_list'>
                                <p>Без переплат</p>
                                <p>
                                    Онлайн оплата з картки будь-якого
                                    україньского банку
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <CheckoutRes />
            </main>
        </div>
    );
};

export default CheckOut;
