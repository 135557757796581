import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import cn from "clsx";
import s from "./style.module.sass";


import white from "../../img/white-el.png";

import H1Block from '../../components/PageProduct/H1Block/H1Block';
import Advantages from '../../components/PageProduct/Advantages/Advantages';

const ProductPage = () => {
	const page = "yellow"
	return (
		<div className={cn("wrapPage", s.productPage, {[s.productPageYellow]: page ==="yellow"})}>
            <Header logo prev h1='Асортимент' />
			<H1Block />
			<Advantages />
				<Footer />
		</div>
	);
};

export default ProductPage;
