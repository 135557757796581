import React, {useLayoutEffect} from "react";
import gsap from "gsap";

import s from "./s.module.sass";

import cn from "clsx";

import white from "../../../img/white-el.png";
import p1 from "../../../img/p1.jpg";
import p2 from "../../../img/p2.jpg";
import p3 from "../../../img/p3.jpg";
import icon1 from "../../../img/icon-p1.svg";
import icon2 from "../../../img/icon-p2.svg";
import icon3 from "../../../img/icon-p3.svg";


const H1Block = () => {

	useLayoutEffect(() => {
		gsap.timeline().to(`.${s.h1_1}`, { left: 0, duration: .5})
			.to(`.${s.h1_2}`, { right: 0, duration: .5 })
			.to(`.${s.h1_3}`, { left: 0, duration: .5 })
	}, [])
    return (
        <div className={s.productPage_h1block}>
            <div className={s.productPage_select}>
                <button className={s.productPage_selectButton}>
                    Чорний 🍓
                </button>
                <button className={`${s.productPage_selectButton} ${s.active}`}>
                    Білий 🍌
                </button>
            </div>
            <div className='container'>
                <div className={cn("row", s.row)}>
                    <div className={s.h1_pic}>
                        <img src={white} alt='white' />
                    </div>
                    <div className={s.h1block_content}>
						<h1 className={s.productPage_h1block_h1}>
							<span className={s.h1_1}>БАНАН</span>
							<span className={s.h1_2}>БІЛИЙ</span>
							<span className={s.h1_3}>ШОКОЛАД</span>
						</h1>

                        <div className={s.h1block_descr}>
                            <div className={s.h1block_item}>
								<div className={ s.h1block_img }>
									<div className={ s.icon }>
										<img src={icon1} alt="icon1" />
									</div>
                                    <img
                                        src={p1}
                                        alt='Крафтовий білий шоколад на кокосовому молоці'
                                    />
                                </div>
                                <div className={s.h1block_ttl}>
                                    Крафтовий білий шоколад на кокосовому молоці
                                </div>
                            </div>

                            <div className={s.h1block_item}>
								<div className={ s.h1block_img }>
								<div className={ s.icon }>
										<img src={icon2} alt="icon2" />
									</div>
                                    <img
                                        src={p2}
                                        alt='Крафтовий білий шоколад на кокосовому молоці'
                                    />
                                </div>
                                <div className={s.h1block_ttl}>
                                    Крафтовий білий шоколад на кокосовому молоці
                                </div>
                            </div>

                            <div className={s.h1block_item}>
								<div className={ s.h1block_img }>
								<div className={ s.icon }>
										<img src={icon3} alt="icon3" />
									</div>
                                    <img
                                        src={p3}
                                        alt='Крафтовий білий шоколад на кокосовому молоці'
                                    />
                                </div>
                                <div className={s.h1block_ttl}>
                                    Крафтовий білий шоколад на кокосовому молоці
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default H1Block;
