import React from 'react';
import classNames  from "classnames";

const Input = ({ text, required, checkedValue, onRadioChange, ...input }) => {
console.log(checkedValue)
	const {type} = input
	const [value, setValue] = React.useState("")
	const [isChecked, setIsChecked] = React.useState(false)
	const [validate, setValidate] = React.useState(true)

	const handlerInput = (e) => {
		setValue(e.target.value)
		setValidate(true)
	}
	const validateInput = () => {
		if (!value.trim()) {
			setValidate(false)
		}
	}
	const handleRadioChange = (e) => {
        setValue(e.target.value);
        setIsChecked(true);
    };
	return (
	<>
		{ type === "text" ? (
			<label className={ classNames("label", {
				error: !validate
			})}>
				<p className="label_ttl">{ text } {required && <span className="red">*</span> }</p>
				<input
					{ ...input }
					onChange={ (e) => handlerInput(e) }
					onBlur={validateInput}
					value={ value } />
			</label>
			) :  (
				<label className={ classNames("label ", {
					error: !validate,
					active: checkedValue === text,
					radio: type === "radio"
				})}>
					
					<input
						{ ...input }
							onChange={ (e) => onRadioChange(text) }
							checked={checkedValue === text}
						value={ text } />
						<p className="label_ttl">{ text } {required && <span className="red">*</span> }</p>
				</label>
			) 
			 }
			</>
		
	);
};

export default Input;
