import React from "react";

import Input from "../../../ui/Input";
import InputPassword from "../../../ui/InputPassword";

const LayPassword = (props) => {
    //console.log(props.bottomBlock);
    const [status, setStatus] = React.useState("2");
    return (
        <div className='lay_wrap email'>
            <div className='container'>
                <div className='flexLay'>
                    <div className='lay_ttl'>
                        <p>
						Встановити новий пароль
                            <span
                                className='close'
                                onClick={() => props.bottomBlock()}
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    viewBox='0 0 20 20'
                                    fill='none'
                                >
                                    <path
                                        d='M16 4L4 16M4 4L16 16'
                                        stroke='#98979A'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                </svg>
                            </span>
                        </p>
                    </div>

                    <InputPassword
						type='text'
						name="oldPassword"
                        text='Старий пароль'
						placeholder='Введи старий пароль'
						password // для того чтоб вывести слово забыли пароль
						errorText="Не правильно введений пароль"
                    />
                    <InputPassword
						type='text'
						name="newPassword"
                        text='Новий пароль'
						placeholder='Введи новий пароль'
						 
                    />
					<InputPassword
						type='text'
						name="doblePassword"
						placeholder='Підтверди новий пароль'
						errorText="Паролі не співпадають"
                    />
                    <div className='button-block'>
                       
                        <button className='button button--white'>
							Встановити
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LayPassword;
