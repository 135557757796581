import React from "react";
import Header from "../components/Header/Header";

 
import Order from "../components/Order/Order";

const MyOrders = () => {
    return (
        <div className='wrapPage'>
            <Header logo prev h1='Мої замовлення' />
            <div className='wrapper wrapper--tal'>
				<Order status="Оплачено" notRound />
				<Order status="Отримано" />
				<Order status="Відправлено" />
				<Order status="Скасовано" />
            </div>
        </div>
    );
};

export default MyOrders;
