import React from 'react';
import ProductChackout from '../ProductChackout/ProductChackout';

import dots from "../../img/dots.svg";

const Order = ({status, notRound}) => {
	return (
		<div className='wrapper_wrap orderInfo'>
					<div className="statusOrder flex">
						<div className="orderInfo_ttl">{status}</div>
						<div className="orderInfo_descr">17.03.24, 11:52</div>
					</div>
					<div className="orderInfo_num">
						<p>№ 000000001</p>
					</div>
					<div className="orderInfo_items">
						<ProductChackout small />
                   		<ProductChackout small />
					</div>
					<div className="orderInfo_pay flex">
						<div className="orderInfo_ttl orderInfo_ttl--500">Оплачено без доставки</div>
						<div className="orderInfo_descr orderInfo_descr-bold">240 ₴</div>
					</div>
					<div class="button-block">
				<button className="button button--white">Повторити замовлення</button>
				{notRound && <div className="orderInfo_mnu">
							<img src={dots} alt="dots" />
						</div>}
						
					</div>
                </div>
	);
};

export default Order;
