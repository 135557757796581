import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    items: [],
    totalPrice: 0,
    selectedPrice: 80,
    totalQuantity: null,
};

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
		addToCart: (state, action) => {
			const { item, actualPrice } = action.payload;
            const existingItem = state.items.find(
                (it) => it.id === item.id
            );
            if (existingItem) {
                existingItem.quantity += 1;
            } else {
                state.items.push({ ...item, quantity: 1 });
            }
            
			state.totalQuantity = calculateTotalQuantity(state.items)
			// const finedPrice = item.prices.find(it => state.totalQuantity >= it.quantity && state.totalQuantity <= it.quantityMax)
			
			const { totalPrice, selectedPrice } = calculateTotalPrice(state.items,  state.totalQuantity)
			state.selectedPrice = selectedPrice
			state.totalPrice = totalPrice
			// console.log(finedPrice.price)
			// console.log(state.totalQuantity)
		},
        addToCartInput: (state, action) => {
            const existingItem = state.items.find(
                (it) => it.id === action.payload.id
            );
            if (existingItem) {
                existingItem.quantity = action.payload.quantity;
            } else {
                state.items.push(action.payload);
            }
            // state.totalPrice = calculateTotalPrice(state.items)
			// state.totalQuantity = calculateTotalQuantity(state.items)
			state.totalQuantity = calculateTotalQuantity(state.items)
			const { totalPrice, selectedPrice } = calculateTotalPrice(state.items,  state.totalQuantity)
			state.selectedPrice = selectedPrice
			state.totalPrice = totalPrice
			
		},
		removeFromCartInput: (state, action) => { // приходит id
			const targetItem = state.items.find(item => item.id === action.payload)
			state.items = state.items.filter(item => item.id !== action.payload);
			console.log(state.selectedPrice)
			state.totalQuantity = calculateTotalQuantity(state.items)
			const { totalPrice, selectedPrice } = calculateTotalPrice(state.items,  state.totalQuantity)
			state.selectedPrice = selectedPrice
			state.totalPrice = totalPrice
		},
		removeFromCart: (state, action) => { // приходит id
			const targetItem = state.items.find(item => item.id === action.payload)
			if (targetItem && targetItem.quantity > 0) {
				targetItem.quantity = targetItem.quantity - 1
			}
			if (targetItem.quantity === 0) {
				state.items = state.items.filter(item => item.id !== action.payload);
			}
			state.totalQuantity = calculateTotalQuantity(state.items)
			const { totalPrice, selectedPrice } = calculateTotalPrice(state.items,  state.totalQuantity)
			state.selectedPrice = selectedPrice
			state.totalPrice = totalPrice
		}
    },
});

const calculateTotalPrice = (items,  quantity) => {
	//console.log(actualPrice)
	console.log(items[0])
	console.log(quantity)
	const finedPrice = items[0].prices.find(it => quantity >= it.quantity && quantity <= it.quantityMax)
	console.log(finedPrice)
	const selectedPrice = finedPrice.price
	//state.selectedPrice = finedPrice.price
	const totalPrice = items.reduce(
		(total, item) => total + finedPrice.price * item.quantity,
		0
	);
	return {totalPrice, selectedPrice}
}
const calculateTotalQuantity = (items) => {
	return items.reduce(
		(total, item) => total + item.quantity,
		0
	);
}


// Action creators are generated for each case reducer function
export const { addToCart, addToCartInput, removeFromCartInput, removeFromCart } = cartSlice.actions;

export default cartSlice.reducer;
