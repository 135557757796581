import React from 'react';
import classNames from "classnames";



const InputPassword = ({ text, errorText, password, required,  ...input }) => {
console.log(input)
	const {type} = input
	const [value, setValue] = React.useState("")
	const [isChecked, setIsChecked] = React.useState(false)
	const [validate, setValidate] = React.useState(true)

	const handlerInput = (e) => {
		setValue(e.target.value)
		setValidate(true)
	}
	const validateInput = () => {
		if (!value.trim()) {
			setValidate(false)
		}
	}
	return (
		<label className={ classNames("label", {
			error: !validate
		})}>
				<p className="label_ttl">
					{ text }
					{ required && <span className="red">*</span> }
					{password && <span className="oldPass">Забув пароль?</span>}
				</p>
			<input
				{ ...input }
				onChange={ (e) => handlerInput(e) }
				onBlur={validateInput}
				value={ value } />
			{errorText && <p className="error">Не правильно введений пароль</p>}
			
		</label>
		
	);
};

export default InputPassword;
