import React from 'react';
import classNames from "classnames";

import pic1 from "../../img/pic1.jpg";

const ProductChackout = (props) => {
	return (
		<div className={ classNames("product-checkout", {
			"product-checkout--small" : props.small
		})}>
                            <div className='product-checkout_img'>
                                <img src={pic1} alt='pic' />
                            </div>
                            <div className='product-checkout_info'>
                                <div className='product-checkout_ttl'>
                                    <p>Rabbit Black</p>
                                </div>
                                <div className='product-checkout_count'>
                                    <span className='grey'>2 &#215;</span> 80 ₴
                                </div>
                            </div>
                        </div>
	);
};

export default ProductChackout;
